import Button from "components/Button";
import { IconCheck } from "components/Icon/IconCheck";
import IconClose from "components/Icon/IconClose";
import { Image } from "components/Image/Image";
import { trackEvent } from "lib/amplitude";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import {
  forwardRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useAuthStore } from "modules/Auth/authStore";
import { useInstitutionsTenant } from "modules/Explore/exploreApi";
import { FilteredKeys } from "lib/object";
import { getSubDomain, isBlueBirdURL, useIsSubdomain } from "lib/host";
import { isBrowser } from "lib/ssr";
import { useSystemStore } from "components/PageHeader/systemStore";
import { IconCheckboxActive } from "components/Icon/IconCheckboxActive";
import { IconCheckboxNonActive } from "components/Icon/IconCheckboxNonActive";
import { Logo } from "components/Logo";

export type FilterProps = {
  isLoadingOn?: boolean;
  onFilter?: (
    a: boolean,
    e: string,
    b: number[],
    c: object | null,
    d: object | null,
    w: object | null
  ) => void;
  onReset?: () => void;
  closeModal?: () => void;
  useAlgolia?: boolean;
  setFilters?: (a: object) => void;
  setSortByDesc?: Dispatch<SetStateAction<string>>;
  filters?: any;
  sortDesc?: string;
  keyword?: string;
  currentSort?: object | null;
  onSetCurrentSort?: (a: object) => void;
  initialCat?: any[];
  initialIns?: any[];
  initialComp?: any[];
  dataCompetences?: any[];
};

const dataRating = [
  {
    id: 1,
    label: "3.0",
    selected: false,
    value: [3.0, 5.0],
  },
  {
    id: 2,
    label: "3.5",
    selected: false,
    value: [3.5, 5.0],
  },
  {
    id: 3,
    label: "4.0",
    selected: false,
    value: [4.0, 5.0],
  },
  {
    id: 4,
    label: "4.5",
    selected: false,
    value: [4.5, 5.0],
  },
];

const dataSort = [
  {
    id: 1,
    label: "highestRating",
    selected: false,
    value: "rating_avg",
    name: "Rating Tertinggi",
  },
  {
    id: 2,
    label: "popular",
    selected: false,
    value: "total_learners",
    name: "Terpopuler",
  },
  {
    id: 3,
    label: "newest",
    selected: false,
    value: "id",
    name: "Terbaru",
  },
];

export const FilterTenant: React.FC<FilterProps> = forwardRef(
  (
    {
      onFilter,
      onReset,
      isLoadingOn = false,
      setFilters,
      filters,
      currentSort,
      sortDesc,
      onSetCurrentSort,
      setSortByDesc,
      keyword,
      closeModal,
      dataCompetences,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation("common");
    const { t: tr } = useTranslation("tags");
    const dataPerView = 6;
    const router = useRouter();
    const [sortings, setSelectedSort] = useState(dataSort);
    const [ratings, setSelectedRatings] = useState(
      dataRating.sort(function (a, b) {
        return b.id - a.id;
      })
    );
    const [isLoading, setIsLoading] = useState({
      reset: false,
      apply: false,
    });
    const [allIns, setInstitutions] = useState([]);
    const [competences, setCompetences] = useState([]);
    const sortKey = (router.query.sortBy as any) ?? "";
    const institutionKey = (router.query.institutionKey as any) ?? "";
    const tagsKey = (router.query.tagsKey as any) ?? "";
    const q = (router.query.q as any) ?? "";
    const isAvailableReset = institutionKey || tagsKey || sortKey;
    const subDomain = isBrowser()
      ? getSubDomain(window.location.hostname)
      : null;
    const isSubdomainTenant = useIsSubdomain();
    const screen = typeof window !== "undefined" && window.innerWidth;
    const isMobile = screen < 770;
    const isBlueBirdTenant =
      typeof window !== "undefined" && isBlueBirdURL(window.location.hostname);

    const classFilterBtn =
      "border rounded-full px-12 py-8 text-b2 font-light flex items-center gap-8 cursor-pointer ";

    const classTitleText = "text-neutral80_to_dark10 text-b1 font-medium";
    const classSubitleText = "text-neutral-70 text-c1 font-light";

    const { currentUser } = useAuthStore((state) => ({
      loginState: state.loginState,
      currentUser: state.currentUser,
    }));

    //@ts-ignore
    const exploreCourseInstitutions = useInstitutionsTenant();

    useEffect(() => {
      if (dataCompetences) {
        setCompetences(dataCompetences.filter((a) => a.tags.length > 0));
      }
    }, [dataCompetences]);
    const refferal = isSubdomainTenant
      ? currentUser?.referral ?? subDomain
      : currentUser?.referral;

    useEffect(() => {
      // if (!currentUser) return;
      if (refferal) {
        exploreCourseInstitutions.doFetch(
          {
            //@ts-ignore
            slug: refferal,
          },
          {
            onSuccess(res?: any) {
              const allInstitutions = res.data.length
                ? res.data.map((a) => {
                    return {
                      name: a.name,
                      image: a.image_url,
                      selected: institutionKey === `${a.name}||${a.image_url}`,
                      slug: a.slug,
                    };
                  })
                : [];
              setInstitutions(allInstitutions);
            },
          }
        );
      }
    }, [refferal]);

    useEffect(() => {
      if (sortKey) {
        setSelectedSort(
          sortings.map((el) =>
            el.value === sortKey
              ? { ...el, selected: true }
              : { ...el, selected: false }
          )
        );
      }
    }, []);

    useEffect(() => {
      let institutionFilter = [];

      if (allIns.length) {
        allIns.forEach((a) => {
          if (a.selected) {
            institutionFilter.push(`${a.name}||${a.image}`);
          }
        });
      }

      if (sortings.length) {
        sortings.forEach((a) => {
          if (a.selected) {
            router.push({
              pathname: "/",
              query: {
                ...router.query,
                sortBy: a.value,
              },
            });
          }
        });
      }

      if (institutionFilter.length > 0) {
        if (q) {
          const filteredQuery = FilteredKeys(["q"], router.query);
          router.push({
            pathname: "/",
            query: {
              ...filteredQuery,
              institutionKey: institutionFilter.join(","),
            },
          });
        } else {
          router.push({
            pathname: "/",
            query: {
              ...router.query,
              institutionKey: institutionFilter.join(","),
            },
          });
        }
      }

      // if (sortKey.length > 0) {
      //   router.push({
      //     pathname: "/",
      //     query: {
      //       ...router.query,
      //       sortBy: sortKey,
      //     },
      //   });
      // }
    }, [allIns, sortings]);

    useEffect(() => {
      let tagsFilter = [];

      if (competences.length) {
        competences.forEach((a) => {
          a.tags.forEach((x) => {
            if (x.selected) {
              tagsFilter.push(x.name);
            }
          });
        });
      }

      if (tagsFilter.length > 0) {
        if (q) {
          const filteredQuery = FilteredKeys(["q"], router.query);
          router.push({
            pathname: "/",
            query: {
              ...filteredQuery,
              tagsKey: tagsFilter.join(","),
            },
          });
        } else {
          router.push({
            pathname: "/",
            query: {
              ...router.query,
              tagsKey: tagsFilter.join(","),
            },
          });
        }
        // router.push({
        //   pathname: "/",
        //   query: {
        //     ...router.query,
        //     tagsKey: tagsFilter.join(","),
        //   },
        // });
      }
    }, [competences]);

    useEffect(() => {
      if (sortings.some((a) => a.selected)) {
        router.push({
          pathname: "/",
          query: {
            ...router.query,
            sortBy: sortings.find((x) => x.selected)?.value,
          },
        });
      }
    }, []);

    useEffect(() => {
      if (!router.query.tagsKey) {
        resetCompetencies();
      }
    }, [tagsKey]);

    useEffect(() => {
      if (!router.query.institutionKey) {
        resetInstitutions();
      }
    }, [institutionKey]);

    const [next, setNext] = useState(dataPerView);
    const [nextIns, setNextIns] = useState(dataPerView);
    const handleMore = () => {
      trackEvent({
        event: "srp_filter_more_a",
        property: {
          page: `B2B Homepage`,
          filter_type: "Kategori",
        },
      });
      setNext(next + dataPerView);
    };

    const handleMoreIns = (totalShowInstitution) => {
      trackEvent({
        event: "srp_filter_more_a",
        property: {
          page: `B2B Homepage`,
          filter_type: "Institusi",
        },
      });
      setNextIns(totalShowInstitution);
    };

    const resetCompetencies = () => {
      setCompetences(
        competences.map((comp) => ({
          ...comp,
          selected: false,
          tags: comp.tags.map((tag) => ({
            ...tag,
            selected: false,
          })),
        }))
      );
    };

    const resetInstitutions = () => {
      setInstitutions(
        allIns.map((a) => {
          return { ...a, selected: false };
        })
      );

      // setInstitutions(newAllIns);
    };

    const resetFilter = () => {
      // remove all query param url
      router.push(
        {
          pathname: "/",
        },
        undefined,
        {
          shallow: true,
        }
      );

      setIsLoading({
        ...isLoading,
        reset: true,
      });

      resetInstitutions();
      setSelectedRatings(
        ratings.map((a) => {
          return { ...a, selected: false };
        })
      );
      setSelectedSort(
        [...sortings].map((el) => {
          return { ...el, selected: false };
        })
      );

      resetCompetencies();

      trackEvent({
        event: "srp_filter_reset_a",
        property: {
          page: `B2B Homepage`,
        },
      });

      setIsLoading({
        ...isLoading,
        reset: false,
      });
      onReset();
    };

    // useImperativeHandle(ref, () => ({
    //   resetFilter,
    // }));

    const handleTagClick = (competenceIndex, tagIndex, info) => {
      const { parent = "", tag = "" } = info;

      trackEvent({
        event: "srp_filter_a",
        property: {
          page: `B2B Homepage`,
          filter_type: parent,
          filter_value: tag,
        },
      });

      const updatedCompetences = [...competences];
      updatedCompetences[competenceIndex].tags[tagIndex].selected =
        !updatedCompetences[competenceIndex].tags[tagIndex].selected;
      setCompetences(updatedCompetences);
    };

    const handleCompetenceClick = (competenceIndex: number, name: String) => {
      trackEvent({
        event: "srp_filter_a",
        property: {
          pageHo: `B2B Homepage`,
          filter_type: name,
          filter_value: name,
        },
      });
      const updatedCompetences = [...competences];
      updatedCompetences[competenceIndex].selected =
        !updatedCompetences[competenceIndex].selected;
      updatedCompetences[competenceIndex].tags.forEach((tag) => {
        tag.selected = updatedCompetences[competenceIndex].selected;
      });
      setCompetences(updatedCompetences);
    };

    const renderInstitusion = () => (
      <div className="grid gap-12 pb-0 px-0 mt-12">
        <div className={`${classTitleText} md:px-0 px-16`}>
          {t("institution")}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-1 gap-12 md:px-0 px-16">
          {allIns?.slice(0, nextIns)?.map((a, i) => (
            <div
              className="flex gap-8 items-center justify-start cursor-pointer"
              key={a.key ?? a.name ?? "" + i}
              onClick={() => {
                const currKey = institutionKey.split(",");

                if (typeof window !== "undefined") {
                  window.scrollTo({
                    top: 200,
                    behavior: "smooth",
                  });
                }

                if (a.selected && currKey.includes(`${a.name}||${a.image}`)) {
                  const newKey = currKey.filter(
                    (y) => y !== `${a.name}||${a.image}`
                  );

                  router.push({
                    pathname: "/",
                    query: {
                      ...router.query,
                      institutionKey: newKey.join(","),
                    },
                  });
                }

                trackEvent({
                  event: "srp_filter_a",
                  property: {
                    page: `B2B Homepage`,
                    filter_type: "Institusi",
                    filter_value: a.name,
                  },
                });
                setInstitutions(
                  allIns.map((x) =>
                    x.name == a.name
                      ? { ...x, selected: !x.selected }
                      : { ...x }
                  )
                );
              }}
            >
              {a.selected ? <IconCheckboxActive /> : <IconCheckboxNonActive />}
              <div
                className={
                  "w-32 h-32 rounded-full flex justify-center items-center " +
                  (theme == "dark" ? "bg-white_to_white" : "")
                }
              >
                {a.image ? (
                  <Image
                    width={32}
                    height={32}
                    src={a.image}
                    alt={a.name}
                    className="w-32 h-32 object-cover object-center"
                  />
                ) : (
                  <div className="w-32 h-32 bg-neutral-20 rounded-full"></div>
                )}
              </div>
              <div className="text-neutral-80 max-w-60 md:max-w-130 text-b2 font-light line-clamp-1">
                {a.name}
              </div>
            </div>
          ))}
        </div>

        <Button
          variant={"secondary_transparant"}
          className="border-none !text-purple50_to_white"
          isFull
          onClick={() =>
            handleMoreIns(
              nextIns < allIns?.length ? allIns?.length : dataPerView
            )
          }
        >
          {nextIns < allIns?.length ? t("seeMore") : t("seeLess")}
        </Button>
      </div>
    );

    const theme = useSystemStore((state) => state.theme);

    const [displayedTagsCounts, setDisplayedTagsCounts] = useState(
      [1, 2].map(() => 3)
    );

    const handleSeeMoreClick = (
      competenceIndex: number,
      title: String,
      totalShowCompetences: number
    ) => {
      trackEvent({
        event: "srp_filter_more_a",
        property: {
          page: `B2B Homepage`,
          filter_type: title,
        },
      });
      setDisplayedTagsCounts((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[competenceIndex] = totalShowCompetences;
        return newCounts;
      });
    };

    const renderCompetences = () => (
      <>
        {competences?.map((c, competenceIndex) => (
          <div className="mb-14 md:px-0 px-16">
            <div
              className="flex gap-12 items-center font-light cursor-pointer my-8"
              key={c + Math.random()}
              onClick={() => handleCompetenceClick(competenceIndex, c.name)}
            >
              {c.selected ? <IconCheckboxActive /> : <IconCheckboxNonActive />}
              <h3 className="text-b1 text-neutral80_to_dark10 font-medium mb-2">
                {tr(c.name)}
              </h3>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-1 gap-12">
              {c.tags
                // .slice(0, displayMoreTags[competenceIndex] ? c.tags.length : 3)
                .slice(0, displayedTagsCounts[competenceIndex])
                .map((t, tagIndex) => {
                  const info = {
                    parent: c.name,
                    tag: t.name,
                  };
                  return (
                    <div
                      className="flex gap-12 items-center font-light cursor-pointer"
                      key={t + Math.random()}
                      onClick={() => {
                        handleTagClick(competenceIndex, tagIndex, info);
                        const currTags = tagsKey.split(",");
                        if (!t.selected && currTags.includes(t.name)) {
                          const newKey = currTags.filter((x) => x !== t.name);
                          router.push({
                            pathname: "/",
                            query: {
                              ...router.query,
                              tagsKey: newKey.join(","),
                            },
                          });
                        }
                      }}
                    >
                      {t.selected ? (
                        <IconCheckboxActive />
                      ) : (
                        <IconCheckboxNonActive />
                      )}
                      <div className="text-neutral80_to_dark10 max-w-100 md:max-w-200 text-b2 font-light line-clamp-2">
                        {tr(t.name)}
                      </div>
                    </div>
                  );
                })}
            </div>

            <Button
              variant={"secondary_transparant"}
              className="border-none !text-purple50_to_dark10 !my-8"
              isFull
              //
              onClick={() =>
                handleSeeMoreClick(
                  competenceIndex,
                  c.name,
                  c.tags.length > displayedTagsCounts[competenceIndex]
                    ? c.tags.length
                    : 3
                )
              }
            >
              {c.tags.length > displayedTagsCounts[competenceIndex]
                ? t("seeMore")
                : t("seeLess")}
            </Button>

            <div className="border-b border-b-neutral-10 w-[95%] mx-auto"></div>
          </div>
        ))}
      </>
    );

    const onApply = () => {
      closeModal();
      trackEvent({
        event: "srp_filter_submit_a",
        property: {
          page: `B2B Homepage`,
        },
      });
    };

    return (
      <div>
        <div className="flex px-16 pt-24 pb-8 justify-between items-center md:px-0 md:!border-b-0 border-b border-b-neutral20_to_dark30">
          <h3 className="text-b1 text-neutral-80 font-medium md:hidden block">
            Filter
          </h3>

          {isAvailableReset && (
            <div
              className="cursor-pointer text-purple-50 hidden md:block text-b1 font-medium"
              onClick={() => resetFilter()}
            >
              Reset filter
            </div>
          )}
          <div onClick={closeModal} className="md:hidden">
            <IconClose size={20} />
          </div>
        </div>

        {/* max-h-[70vh] md:max-h-[75vh] */}

        <div
          className={`hide-scrollbar flex flex-col pb-8 ${
            isBlueBirdTenant ? "md:max-h-[70vh]" : "md:max-h-[75vh]"
          } max-h-[40vh] gap-12 overflow-scroll`}
        >
          <div className="grid gap-12">
            <div
              className={`grid gap-12 ${isAvailableReset ? "pt-8" : "pt-0"}`}
            >
              <div className={`${classTitleText} md:px-0 px-16`}>
                {t("sort")}
              </div>
              <div
                className={"flex md:px-0 px-16 flex-wrap " + classSubitleText}
              >
                {sortings.map((a) => {
                  return (
                    <div
                      className={`${
                        classFilterBtn +
                        (a.selected
                          ? // ? "bg-purple-50 border-purple-50 text-white_to_white"
                            // : "border-neutral-40 text-neutral-70"

                            "bg-purple-50 text-white_to_white border-purple-50"
                          : "border-purple05_to_dark20 text-neutral80_to_dark10 bg-purple05_to_dark20")
                      } flex justify-center mb-12 mr-12`}
                      onClick={() => {
                        router.push({
                          pathname: "/",
                          query: {
                            ...router.query,
                            sortBy: a.value,
                          },
                        });

                        trackEvent({
                          event: "srp_filter_a",
                          property: {
                            page: `B2B Homepage`,
                            filter_type: "Urutkan",
                            filter_value: a.name,
                          },
                        });
                        setSelectedSort(
                          sortings.map((x) =>
                            a == x
                              ? { ...x, selected: true }
                              : { ...x, selected: false }
                          )
                        );
                        setSortByDesc(a.value);
                        onSetCurrentSort({ ...currentSort, [a.value]: true });
                      }}
                      key={Math.random() + "IconFilter"}
                    >
                      {t(a.label)}
                    </div>
                  );
                })}
              </div>
            </div>

            {
              <div>
                <div className="border-b border-b-neutral-10 w-[95%] mx-auto"></div>
                {renderCompetences()}
                {currentUser?.referral !== "kemenperin" ? (
                  renderInstitusion()
                ) : (
                  <></>
                )}
              </div>
            }
          </div>
        </div>

        {isBlueBirdTenant && !isMobile && (
          <div className={`sticky py-12`}>
            <p className="text-b2 font-light">Powered by</p>
            <Logo scale={1.2} useWhite={theme == "dark"} />
          </div>
        )}

        <div className="py-18 md:hidden border-t border-neutral20_to_dark30">
          <div className="px-16 grid grid-cols-2 gap-x-12">
            <Button
              variant="secondary"
              isFull
              rounded="full"
              state={isAvailableReset ? "active" : "disabled"}
              onClick={() => resetFilter()}
            >
              RESET
            </Button>
            <Button
              isFull
              rounded="full"
              state={isAvailableReset ? "active" : "disabled"}
              onClick={onApply}
            >
              TERAPKAN
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
