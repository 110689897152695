import { trackEvent } from "lib/amplitude";
import { useAuthStore } from "modules/Auth/authStore";
import React, { Suspense, useEffect, useRef, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { useCourseStore } from "modules/Course/courseStore";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, FreeMode } from "swiper";
import CardCourseWithLesson from "components/Card/CardCourseWithLesson";
import { IconArrow } from "components/Icon/IconArrow";
import ModalSectionsDetail from "./ModalSectionsDetail";
import { useModalSectionStore } from "./modalSectionStore";
import { useGetSectionMandatoryDetail } from "./homeTenantApi";
import { useRouter } from "next/router";
import { SkeletonCircle } from "components/Skeleton/Skeleton";
SwiperCore.use([Navigation, FreeMode]);

interface SectionProps {
  courses?: any[];
  label?: string;
  isHidden?: any;
  section?: number;
}

const SectionMandatory: React.FC<SectionProps> = ({
  courses,
  label,
  section,
  isHidden = null,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useAuthStore((state) => state.currentUser);
  const [currData, setCurrData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [totalData, setTotalData] = useState(-1);
  const [noMoreData, setNoMoreData] = useState(false);
  const getSectionMandatoryDetail = useGetSectionMandatoryDetail();
  const { t } = useTranslation("common");

  const [shuffledData, setShuffledData] = useState(null);

  const [setSelectedTitle, isShow, setIsShow, setDataSection, setLoading] =
    useModalSectionStore((state) => [
      state.setSelectedTitle,
      state.isShow,
      state.setIsShow,
      state.setDataSection,
      state.setLoading,
    ]);

  const fetchMyCourses = (data) => {
    let newData = data.filter((item) => {
      return !currData.some((existingItem) => existingItem.id === item.id);
    });

    setCurrData((prev) => [...prev, ...newData]);
    setTotalData(data?.length);
    setIsLoading(false);

    // getCourses.doFetch(
    //   {
    //     slug: currentUser?.referral,
    //     limit,
    //     mandatory: 1,
    //     page,
    //   },
    //   {
    //     onSuccess(data) {
    //       let newData = data?.data.filter((item) => {
    //         return !currData.some(
    //           (existingItem) => existingItem.id === item.id
    //         );
    //       });

    //       setCurrData((prev) => [...prev, ...newData]);
    //       setTotalData(data?.meta.total);
    //       setIsLoading(false);
    //     },
    //   }
    // );
  };

  const fetchSectionDetail = () => {
    getSectionMandatoryDetail.doFetch(
      {
        slug: currentUser?.referral,
        course_mandatory: (props as any)?.id,
      },
      {
        onSuccess(data: any) {
          setDataSection(data?.data[0].courses);
          setLoading(false);
        },
      }
    );
  };

  let itemCounts = page * limit;
  if (page > 1) itemCounts = totalData;
  const displayedItems = [...currData].slice(0, itemCounts);
  const loginState = useAuthStore((state) => state.loginState);

  const renderCount = useRef(0);

  useEffect(() => {
    // console.log(courses);
    renderCount.current = renderCount.current + 1;
    if (renderCount.current === 2) {
      setLimit(9);
      return;
    }

    if (!noMoreData) {
      fetchMyCourses(courses);
    }
  }, [courses, page]);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  const showLess = displayedItems.length === totalData;
  const isDesktopView =
    typeof window !== "undefined" && window.screen.width >= 480;

  const settings = {
    className: "swiper-customized",
    freeMode: isDesktopView,
    spaceBetween: 20,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        slidesPerGroup: 1.2,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      // when window width is >= 912px
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
  };

  const buttonHandler = (type: string) => {
    trackEvent({
      event: "b2b_homepage_course_more_a",
      property: {
        category_name: label,
        button_type: type,
        section: section,
      },
    });

    if (noMoreData && showLess) {
      setLimit(3);
      setPage(1);
      return;
    }
    setPage((p) => p + 1);
  };

  useEffect(() => {
    setNoMoreData(currData.length >= totalData);
  }, [currData]);

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;
  const router = useRouter();

  useEffect(() => {
    if (currData.length > 0) {
      const shuffled = [...currData].sort(() => Math.random() - 0.5);
      setShuffledData(shuffled);
    }
  }, [currData]);

  const showedItems = shuffledData?.slice(0, 3);

  return (
    <div className="mb-32 relative">
      {label ? (
        // <h2
        //   onClick={() => buttonHandler("Section Title")}
        //   className="text-neutral-80 text-h4 font-medium mb-16 cursor-pointer"
        // >
        //   {label}

        // </h2>
        <div
          className={
            "flex items-center justify-between md:justify-start gap-8 px-16 py-20 gap-20 xl-max:py-12"
          }
        >
          {/* <h2 className="text-neutral-80 text-h4 font-medium">{label}</h2> */}
          <h2
            className={
              "text-neutral-80 text-h4 font-medium " +
              (currData && currData.length > 3 ? "cursor-pointer" : "")
            }
            onClick={() => {
              if (!isMobile) {
                setSelectedTitle(label);
                setIsShow(!isShow);
                fetchSectionDetail();
              } else {
                (router as any).push({
                  pathname: "/section-detail/[id]",
                  query: {
                    id: (props as any).id,
                    section: "mandatory",
                  },
                });
              }
            }}
          >
            {label}
          </h2>

          {currData && currData.length > 3 && (
            <div
              className="flex gap-4 text-b2 font-medium cursor-pointer text-purple50_to_dark10"
              onClick={() => {
                if (!isMobile) {
                  setSelectedTitle(label);
                  setIsShow(!isShow);
                  fetchSectionDetail();
                } else {
                  (router as any).push({
                    pathname: "/section-detail/[id]",
                    query: {
                      id: (props as any).id,
                      section: "mandatory",
                    },
                  });
                }
              }}
            >
              <span className="hidden md:block">{t("viewAll")}</span>
              <IconArrow isCaret={true} direction="right" color="#551FC1" />
            </div>
          )}
        </div>
      ) : null}

      {currData && currData.length > 0 ? (
        <>
          {isDesktopView ? (
            <Swiper
              {...settings}
              navigation={isDesktopView && currData.length > 4}
              onSlideNextTransitionStart={(_) => {
                buttonHandler("Next Courses");
              }}
              onSlidePrevTransitionStart={(_) => {
                buttonHandler("Previous Courses");
              }}
            >
              {showedItems?.slice(0, 3).map((course, index) => (
                <SwiperSlide key={"Swiper-" + course.slug}>
                  <Suspense fallback={<SkeletonCircle />}>
                    <CardCourseWithLesson
                      {...course}
                      tracker={{
                        event: "b2b_homepage_course_a",
                        property: {
                          course_name: course?.name,
                          course_slug: course?.slug,
                          institution_name: course?.institution?.name,
                          category_name: label,
                          section: label,
                          index,
                          is_mandatory_course: false,
                        },
                      }}
                    />
                  </Suspense>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div
              className={`${
                isHidden !== null ? "px-16" : "px-0"
              } md:px-0 flex gap-12 overflow-x-scroll`}
            >
              {currData
                .sort(() => Math.random() - 0.5)
                .map((course, index) => (
                  <Suspense fallback={<SkeletonCircle />}>
                    <CardCourseWithLesson
                      {...course}
                      tracker={{
                        event: "b2b_homepage_course_a",
                        property: {
                          course_name: course?.name,
                          course_slug: course?.slug,
                          institution_name: course?.institution?.name,
                          category_name: label,
                          section: label,
                          index,
                          is_mandatory_course: false,
                        },
                      }}
                      wrapClass="w-[300px] md:w-full"
                    />
                  </Suspense>
                ))}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SectionMandatory;
