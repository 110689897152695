import { HorizontalScroll } from "components/Pagination/HorizontalScroll";
import { getSubDomain, useIsSubdomain } from "lib/host";
import { isBrowser } from "lib/ssr";
import { useAuthStore } from "modules/Auth/authStore";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useGetCoursesByTenantSlug } from "./homeTenantApi";

interface KeywordsProp {
  keywordOthers?: any[];
  onReset?(): void;
  setKeywordOthers?: (a: any) => void;
  setCoursesAfterClicked?: (a: any) => void;
}

const Keywords: React.FC<KeywordsProp> = ({
  keywordOthers,
  onReset,
  setKeywordOthers,
  setCoursesAfterClicked,
}) => {
  const { currentUser } = useAuthStore((state) => ({
    loginState: state.loginState,
    currentUser: state.currentUser,
  }));
  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;

  const router = useRouter();
  const isMobileView = typeof window !== "undefined" && window.innerWidth < 768;
  //   @ts-ignore
  const getCourses = useGetCoursesByTenantSlug();
  let referral = useIsSubdomain()
    ? currentUser?.referral ?? subDomain
    : currentUser?.referral;

  return (
    <HorizontalScroll
      className="md:mb-0 !gap-0"
      paddingHorizontal={0}
      top={12}
      useNavigation={isMobileView ? false : true}
    >
      {keywordOthers.map((keys: any) => (
        <div
          className={`border rounded-full cursor-pointer px-12 py-8 text-center inline-flex flex-row items-center min-w-12 whitespace-nowrap m-8 ml-0 mr-8 md:mr-10 text-b2 font-light ${
            keys.active
              ? "bg-purple-50 text-white_to_white border-purple-50"
              : "border-purple05_to_dark20 text-neutral80_to_dark10 bg-purple05_to_dark20"
          }`}
          onClick={() => {
            onReset();
            router.push(
              {
                pathname: "/",
              },
              undefined,
              {
                shallow: true,
              }
            );
            setKeywordOthers(
              keywordOthers.map((el) =>
                el.name === keys.name
                  ? { ...el, active: !el.active }
                  : { ...el, active: false }
              )
            );
            const arr = [keys.name];
            if (referral && !["moodle", "example"].includes(referral)) {
              getCourses.doFetch(
                {
                  slug: referral,
                  "others[]": arr,
                },
                {
                  onSuccess(data) {
                    setCoursesAfterClicked(data?.data);
                  },
                }
              );
            }
          }}
        >
          {keys.name}
        </div>
      ))}
    </HorizontalScroll>
  );
};

export default Keywords;
