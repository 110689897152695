import React, { useCallback, useEffect, useRef, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import Modal from "components/Modal";
import {
  SkeletonCircle,
  SkeletonRect,
  SkeletonWrapper,
} from "components/Skeleton/Skeleton";
import { useModalSectionStore } from "./modalSectionStore";
import CardCourseWithLesson from "components/Card/CardCourseWithLesson";
import { MoonLoader } from "react-spinners";
import { useIsDesktop } from "lib/device";

const ModalSectionsDetail: React.FC<{}> = ({}) => {
  const { t } = useTranslation("common");
  const isDesktop = useIsDesktop();
  const [title, isShow, setIsShow, data, isLoading] = useModalSectionStore(
    (state) => [
      state.title,
      state.isShow,
      state.setIsShow,
      state.data,
      state.isLoading,
    ]
  );

  // Load More
  const [visibleData, setVisibleData] = useState(data?.slice(0, 9) || []);
  const [hasMoreData, setHasMoreData] = useState(true);
  const observerRef = useRef<HTMLDivElement | null>(null);

  const loadMoreData = useCallback(() => {
    if (data) {
      const nextData = data.slice(visibleData.length, visibleData.length + 9);

      setVisibleData((prev) => [...prev, ...nextData]);

      if (visibleData.length + nextData.length >= data.length) {
        setHasMoreData(false);
      }
    }
  }, [data, visibleData.length]);

  useEffect(() => {
    if (data) {
      setVisibleData(data.slice(0, 9));
      setHasMoreData(true);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreData) {
          loadMoreData();
        }
      },
      { threshold: 0.5 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [hasMoreData, loadMoreData]);
  // End Load More

  useEffect(() => {
    if (visibleData) {
      setShuffledData(visibleData);
    }
  }, [visibleData]);

  const [shuffledData, setShuffledData] = useState(visibleData || []);

  useEffect(() => {
    if (title !== t("sectionNew") || title !== "Kursus Paling Banyak Dicari") {
      const shuffled = [...data].sort(() => Math.random() - 0.5);
      setShuffledData(shuffled);
    }
  }, [data.length]);

  return (
    <Modal
      title={title}
      isShow={isShow}
      contentClassName="min-w-[90vw]"
      onClose={() => setIsShow(false)}
      isFullScreen
    >
      <div className="grid grid-cols-3 gap-20 px-16 max-h-[75vh] pt-20 pb-40 overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        {isLoading ? (
          Array.from({ length: 9 }).map((a: number, __) => (
            <SkeletonWrapper className="grid gap-8">
              <SkeletonRect className="rounded-4 h-[223px]" />
              <SkeletonRect className="rounded-4 h-[76px]" />
            </SkeletonWrapper>
          ))
        ) : (
          <>
            {title === t("sectionNew") ||
            title === "Kursus Paling Banyak Dicari"
              ? visibleData.map((elm, index) => (
                  <CardCourseWithLesson
                    {...elm}
                    tracker={{
                      event: "b2b_homepage_course_a",
                      property: {
                        course_name: elm?.name,
                        course_slug: elm?.slug,
                        institution_name: elm?.institution?.name,
                        category_name: title,
                        section: title,
                        index,
                        is_mandatory_course: false,
                      },
                    }}
                    isModal={true}
                  />
                ))
              : shuffledData.map((elm, index) => (
                  <CardCourseWithLesson
                    {...elm}
                    tracker={{
                      event: "b2b_homepage_course_a",
                      property: {
                        course_name: elm?.name,
                        course_slug: elm?.slug,
                        institution_name: elm?.institution?.name,
                        category_name: title,
                        section: title,
                        index,
                        is_mandatory_course: false,
                      },
                    }}
                    isModal={true}
                  />
                ))}
            {hasMoreData && (
              <div ref={observerRef} className="mt-10 flex justify-center">
                <MoonLoader size={isDesktop ? 45 : 20} />
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalSectionsDetail;
